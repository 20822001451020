<template>
  <div class="form-elements">
    <div class="row">
      <div class="col-md-3">
        <vuestic-widget :headerText="$t('view.profile.title')">
          <div class="row">
            <div class="col-md-3"></div>
            <div class="col-md-6">
              <img src="@/assets/images/profile.jpg" class="img-fluid" width="auto">
            </div>
            <div class="col-md-3"></div>
          </div>
          <br>
          <div class="row justify-content-md-center">
            <ul class="list-group">
              <li class="list-group-item list-group-item-action">Nombre: {{ me.name }}</li>
              <li class="list-group-item">Email: {{ me.email }}</li>
              <li class="list-group-item">Rol: {{ me.rol.name }}</li>
              <li class="list-group-item">Último acceso: {{ me.last_login }}</li>
              <li class="list-group-item">Cantidad de accesos:{{ me.count_login + 1 }}</li>
             
            </ul>
          </div>
        </vuestic-widget>
      </div>
      <div class="col-md-3">
        <vuestic-widget :headerText="'view.profile.updateData' | translate">
          <form @submit.prevent autocomplete="off">
            <div class="row">
              <div class="col-md-12">
                <fieldset>
                  <div class="row">
                    <div class="col-md-12 with-icon-right">
                      <div
                      class="form-group with-icon-right"
                      :class="{'has-error': errors.has('name'), 'valid': isSuccessNameValid}"
                      >
                      <div class="input-group">
                        <input id="name" name="name" v-model="name" v-validate="'required'">
                        <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                        <i class="fa fa-check valid-icon icon-right input-icon"></i>
                        <label
                        class="control-label"
                        for="name"
                        >{{'view.user.fields.name' | translate}}</label>
                        <i class="bar"></i>
                        <small
                        v-show="errors.has('name')"
                        class="help text-danger"
                        >{{ errors.first('name') }}</small>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
              <div class="col-md-3 offset-md-12">
                <button
                class="btn btn-primary btn-sm"
                type="button"
                v-on:click="updateNameForm"
                >{{$t('view.profile.updateName')}}</button>
              </div>
            </div>
          </div>
        </form>
      </vuestic-widget>
    </div>

    <div class="col-md-6">
      <vuestic-widget :headerText="'view.profile.updatePassword' | translate">
        <form @submit.prevent="updatePasswordForm" autocomplete="off">
          <div class="row">
            <div class="col-md-12">
              <fieldset>
                <div class="row">
                  <div class="col-md-12 with-icon-right">
                    <div class="form-group with-icon-right" :class="{'has-error': errors.has('oldpassword')}" >
                      <div class="input-group">
                        <input type="password" id="oldpassword" name="oldpassword" v-model="oldPassword" v-validate="'required'" >
                        <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                        <i class="fa fa-check valid-icon icon-right input-icon"></i>
                        <label class="control-label" for="oldpassword" >{{'view.profile.fields.oldPassword' | translate}}</label>
                        <i class="bar"></i>
                        <small v-show="errors.has('oldpassword')" class="help text-danger">{{ errors.first('oldpassword') }}</small>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 with-icon-right">
                    <div class="form-group with-icon-right" :class="{'has-error': errors.has('newPassword')}">
                      <div class="input-group">
                        <input type="password" id="newPassword" name="newPassword" v-model="newPassword" v-validate="'required|min:6'">
                        <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                        <i class="fa fa-check valid-icon icon-right input-icon"></i>
                        <label class="control-label" for="newPassword">{{'view.profile.fields.newPassword' | translate}}</label>
                        <i class="bar"></i>
                        <small v-show="errors.has('newPassword')" class="help text-danger">{{ errors.first('newPassword') }}</small>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 with-icon-right">
                    <div class="form-group with-icon-right" :class="{'has-error': errors.has('confirmPassword')}">
                      <div class="input-group">
                        <input type="password" id="confirmPassword" name="confirmPassword" v-model="confirmPassword" v-validate="'required|confirmed:newPassword'">
                        <i class="fa fa-exclamation-triangle error-icon icon-right input-icon"></i>
                        <i class="fa fa-check valid-icon icon-right input-icon"></i>
                        <label class="control-label" for="confirmPassword" >{{'view.profile.fields.confirmPassword' | translate}}</label>
                        <i class="bar"></i>
                        <small v-show="errors.has('confirmPassword')" class="help text-danger" >{{ errors.first('confirmPassword') }}</small>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
              <div class="col-md-3 offset-md-12">
                <button class="btn btn-primary btn-sm" type="submit" >{{$t('view.profile.updatePassword')}}</button>
              </div>
            </div>
          </div>
        </form>
      </vuestic-widget>
    </div>
  </div>
</div>
</template>

<script>
  import { mapState, mapActions } from "vuex";

  export default {
    name: "New_User",
    data() {
      return {
        name: "",
        email: "",
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
        rol: "",
        rolesTeams: []
      };
    },
    mounted() {
      this.name = this.me.name;
    },
    methods: {
      ...mapActions([
        "loadRolesTeams",
        "storeUser",
        "addToastMessage",
        "updateName",
        "updatePassword"
        ]),
      updateNameForm() {
        this.updateName({
          name: this.name,
          id: this.me.id
        })
        .then(data => {
          this.addToastMessage({
            text: data.message,
            type: "success"
          });
        })
        .catch(data => {
          this.error = data.message;
        });
      },
      updatePasswordForm() {
        this.$validator.validate().then(result => {
         if (result) {

          this.updatePassword({
            old_password: this.oldPassword,
            new_password: this.newPassword,
            confirm_password: this.confirmPassword,
            id: this.me.id
          })
          .then(data => {
            this.addToastMessage({
                text: data.message,
                type: 'success'
            })
            this.cleanPassword();
            this.$router.replace('/profile')
          })
          .catch(data => {
            this.error = data.message
          });

        }
      });
      },
      clear(field) {
        this[field] = "";
      },
      cleanPassword() {
        this.oldPassword = "";
        this.newPassword = "";
        this.confirmPassword = "";
      },
      cleanForm() {
        this.name = "";
      },
    isSuccessNameValid() {
      let isValid = false;
      if (this.name) {
        isValid = this.name.validated && this.name.valid;
      }
      return isValid;
    },
    isSuccessfulEmailValid() {
      let isValid = false;
      if (this.email) {
        isValid = this.email.validated && this.email.valid;
      }
      return isValid;
    }
  },
  computed: {
    ...mapState({
      me: state => state.auth.me
    })
  }
};
</script>
